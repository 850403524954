import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'

import translationEnglish from '@/translations/en/translation.json'
import translationSpanish from '@/translations/es/translation.json'

const lng = localStorage.getItem('i18nextLng')

const resources = {
  en: {
    translation: translationEnglish,
  },
  es: {
    translation: translationSpanish,
  },
}
//@ts-ignore
i18next.use(initReactI18next).init({
  resources,
  lng,
  returnNull: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    // format: (value, format, lng) => { // legacy usage
    //   if (value instanceof Date) {
    //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
    //   }
    //   return value;
    // }
  },
})

export default i18next
