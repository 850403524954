import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const CheckEmail = lazy(() => import('@/pages/check-email/check-email.tsx'))
const ClientSetup = lazy(() => import('@/pages/client-setup/client-setup.tsx'))
const EmailConfirmed = lazy(() => import('@/pages/email-confirmed/email-confirmed.tsx'))
const EmailConfirming = lazy(() => import('@/pages/email-confirming/email-confirming.tsx'))
const FinishSetup = lazy(() => import('@/pages/finish-setup/finish-setup.tsx'))
const Layout = lazy(() => import('@/pages/layout.tsx'))
const ScheduleSetup = lazy(() => import('@/pages/schedule-setup/schedule-setup.tsx'))
const SignUp = lazy(() => import('@/pages/sign-up/sign-up.tsx'))
const RequireAuth = lazy(() => import('@/pages/require-auth.tsx'))
const Dashboard = lazy(() => import('@/pages/dashboard/dashboard.tsx'))
const SignIn = lazy(() => import('@/pages/sign-in/sign-in.tsx'))
const ForgotPassword = lazy(() => import('@/pages/forgot-password/forgot-password.tsx'))
const CheckEmailResetPassword = lazy(
  () => import('@/pages/check-email-reset-password/check-email-reset-password.tsx'),
)
const ResetPassword = lazy(() => import('@/pages/reset-password/reset-password.tsx'))
const DashboardTemplate = lazy(
  () => import('@/templates/dashboard-template/dashboard-template.tsx'),
)
const Clients = lazy(() => import('@/pages/clients/clients.tsx'))
const CompanyInfo = lazy(() => import('@/pages/profile-settings/company-info/company-info.tsx'))
const ChangePassword = lazy(
  () => import('@/pages/profile-settings/change-password/change-password.tsx'),
)
const ReportSettings = lazy(
  () => import('@/pages/profile-settings/report-settings/report-settings.tsx'),
)
const ProfileSettings = lazy(
  () => import('@/pages/profile-settings/profile-settings/profile-settings.tsx'),
)
const ChangeEmail = lazy(() => import('@/pages/change-email/change-email.tsx'))
const SingleClient = lazy(() => import('@/pages/single-client/single-client.tsx'))
const Invoices = lazy(() => import('@/pages/invoices/invoices.tsx'))
const Faq = lazy(() => import('@/pages/faq/faq.tsx'))
const Reports = lazy(() => import('@/pages/reports/reports.tsx'))
const SingleErrorFix = lazy(() => import('@/pages/single-error-fix/single-error-fix.tsx'))
const SingleInvoice = lazy(() => import('@/pages/single-invoice/single-invoice.tsx'))
const Subscriptions = lazy(() => import('@/pages/subscriptions/subscriptions.tsx'))
const AddBankStatement = lazy(() => import('@/pages/add-bank-statement/add-bank-statement.tsx'))
const ViewBankStatement = lazy(() => import('@/pages/view-bank-statement/view-bank-statement.tsx'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="check-email" element={<CheckEmail />} />
        <Route path="confirming-email" element={<EmailConfirming />} />
        <Route path="confirmed-email" element={<EmailConfirmed />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password/check-email" element={<CheckEmailResetPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        {/* private routes */}
        <Route element={<RequireAuth />}>
          <Route path="client-setup" element={<ClientSetup />} />
          <Route path="schedule-setup" element={<ScheduleSetup />} />
          <Route path="finish-setup" element={<FinishSetup />} />
          <Route path="change-email" element={<ChangeEmail />} />
          <Route path="/" element={<DashboardTemplate />}>
            <Route path="" element={<Dashboard />} />
            <Route path="clients" element={<Clients />} />
            <Route path="clients/:id" element={<SingleClient />} />
            <Route path="profile-settings" element={<ProfileSettings />}>
              <Route path="company-info" element={<CompanyInfo />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="report-settings" element={<ReportSettings />} />
            </Route>
            <Route path="invoices" element={<Invoices />} />
            <Route path="reports" element={<Reports />} />
            <Route path="reports/:id/:invoiceId" element={<SingleErrorFix type="group" />} />
            <Route
              path="reports/all/:invoiceGroupId/:invoiceId"
              element={<SingleErrorFix type="all" />}
            />
            <Route
              path="reports/all-errors/:invoiceId"
              element={<SingleErrorFix type="all-errors" />}
            />
            <Route
              path="reports/company/:companyId/:invoiceId"
              element={<SingleErrorFix type="company" />}
            />
            <Route path="faq/:id" element={<Faq />} />
            <Route
              path="invoices/:companyId/:invoiceId"
              element={<SingleInvoice type="company" />}
            />
            <Route path="invoices/all/:invoiceId" element={<SingleInvoice type="all" />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="banking/add" element={<AddBankStatement />} />
            <Route path="banking/view" element={<ViewBankStatement />} />
          </Route>
        </Route>

        {/* 404 */}
        <Route path="*" element={<div>404</div>} />
      </Route>
    </Routes>
  )
}

export { AppRoutes }
