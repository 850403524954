import { Worker } from '@react-pdf-viewer/core'
import { FC } from 'react'

import { AppRoutes } from '@/components/app-routes'

const App: FC = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <AppRoutes />
    </Worker>
  )
}

export { App }
